import { useEffect, useState } from "react";
import classes from "./FooterMobile.module.css";

const FooterMobile = () => {
  const [termsVisible, setTermsVisible] = useState(false);

  useEffect(async () => {}, [termsVisible]);
  // // Get the button that opens the modal
  // var btn = document.getElementsByClassName("TermsAndConditions");

  // // Get the <span> element that closes the modal
  // var span = document.getElementsByClassName("close")[0];

  // When the user clicks on the button, open the modal
  function openModel() {
    setTermsVisible(true);
  }

  // When the user clicks on <span> (x), close the modal
  function closeModel() {
    setTermsVisible(false);
  }
  return (
    <div>
      {termsVisible == true ? (
        <div className={classes.TermsModal}>
          <div className={classes.TermsModalContent}>
            <span
              className={classes.TermsModalCloseButton}
              onClick={closeModel}
            >
              &times;
            </span>
            <p className={classes.TermsModalTitle}>White Paper</p>
            <p className={classes.TermsModalContent}>
              <p className={classes.TermsModalTitle}>THE COLLECTION </p>
              HOUSE OFBELIEVERS Gen 1 collectibles is a collection of 4444 unique bears,
              which are part of a collection of 8888 unique bears in total, born in the
              ethereum blockchain. Bears are categorized with different traits and levels of rarity, 
              as well as 1/1 specials. The collection is made in collaboration with DISTRITO MODA LLC 
              which holds legitimate contractual rights to use the trademark “HOUSE OFBELIEVERS” 
              within the purpose of the collection HOUSE OFBELIEVERS.
              <p className={classes.TermsModalTitle}>VISION</p> 
              New brands are driven by the Community, and NFTs are the best way to mutually benefit
              from their success. Subsequently,  due to the fact it is DAO driven this creates a working 
              opportunity for creators to pioneer a Web3 Street Style Fashion Brand with one of Italy&#39;s 
              finest Fashion Icons “Mariano Di Vaio”.
              <p className={classes.TermsModalTitle}>MISSION</p> 
              The revolution has started, and we will put our know-how as a 10 years established 100% 
              digital fashion brand to our squad disposal, in order to join forces, create the magic and 
              together build a new revolutionary streety style brand 3.0 Join the Squad and be an active 
              part of this new era with an opportunity to work for the DAO and display your talent globally!
              <p className={classes.TermsModalTitle}>ROADMAP</p>
              <p className={classes.TermsModalTitle}>Q2</p>
              <ul className={classes.listitems}>
                <li>
                  <span className={classes.bolditems}>Alpha Group</span>
                  <p> 
                  This will not be your typical server alpha group. We will do NFT&#39;s giveaways of other 
                  projects to raffle winners as well as 1/1 collection item giveaways done by our very 
                  own artist Fieno. The first one will be a canvas of your bear! We are also working on 
                  recruiting an influential candidate to partner with sought out projects and give WL 
                  opportunities. For example, Moonbirds, Doodles, Azuki, Otherside etc. caliber projects. 
                  We will also act on projects you would like a WL access to as well. Channels that will 
                  be released are #Crypto talk, #AlphaCalls, #NFT talk, #Shills, #Giveaways. Requirement 
                  is 4+ NFT&#39;s. We will release a new role which will be granted by collabland “ALPHA BEARS”.
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  VIP MOTOGP
                  </span>
                  <p>
                  <i>MotoGP Gran Premio D&#39;Italia</i> Sun, 29 May, 14:00, Track: Mugello Circuit 2 NFT Holders will have 
                  the tickets embedded in the TokenID as a trait for the VIP Access Moto event.
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  DAO WORKING PODS
                  </span>
                  <p>                
                  The opportunity to work and pioneer a fashion brand with the NOHOW team behind the scenes. 
                  Pod leaders will be chosen by experience and activity through a vetted process by the community and 
                  NOHOW TEAM. Other contributors will be chosen by the NOHOW team and Pod Leaders for the following pods; 
                  Analytics pod, Community Product Pod, Designer Pod, Dev Pod, Events Pod, Finance Pod, Legal Pod,
                  Marketing Pod, Moderation Pod, Operations Pod, Partnerships Pod, Ecommerce store Pod.
                  </p> 
                </li>
                <li>
                  <span className={classes.bolditems}>
                  Updated Minting Website
                  </span>
                  <p>  
                  In order to have a stronger reach to the web2 Fashion community we will be processing our mints with 
                  Credit Cards in the new minting website. They merely have to connect a wallet and then they can purchase 
                  the NFT by Credit Card. This will make onboarding newcomers much easier.
                  </p>   
                </li>
                <li>
                  <span className={classes.bolditems}>
                  DAO Entity HOUSE OFBELIEVERS LLC
                  </span>
                  <p>
                  HOUSE OF BELIEVERS will be a registered foundation in Switzerland which will own a registered LLC in 
                  Switzerland “HOUSE OF BELIEVERS LLC” that will be governed by the DAO. All documents in regards to 
                  quarterly share certificates for the royalty system and taxes will be processed under this entity. 
                  10 % royalties will be paid to this entity in respect to the brand licensing agreement.
                  </p>  
                </li>
                <li>
                  <span className={classes.bolditems}>
                  Token Gated Merch Website
                  </span>
                  <p>
                  Connect your wallet to access exclusive merch from our genesis collection. You are able to select your 
                  own personalized token ID. If NFT&#39;s are not revealed by the time of your purchase they will be revealed 
                  to you through the merch when it arrives.
                  </p>   
                </li>
              </ul>
              <p className={classes.TermsModalTitle}>Q3</p>
              <ul className={classes.listitems}>
                <li>
                  <span className={classes.bolditems}>
                  Marketplace
                  </span>
                  <p>
                  We will have our own marketplace where you can sell exclusive 1/1 items and collections! 
                  Not to mention you will also be able to license your NFT for others for specific events 
                  we will be hosting. These events will have a gift bag with items of their unique bears of 
                  those SPECIFIC TOKEN IDS they enter the event with!
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  H.O.B Summer Collection Milano Fashion Event/Runway
                  </span>
                  <p>
                  We will be hosting our very own runway and fashion event of our summer collection. 
                  We will be inviting 5 Female and 5 Male models from our very own community to model 
                  this collection. If you don&#39;t know how to model don&#39;t worry MDV will be giving you a 
                  crash course for modeling and secrets he learned throughout his career.There will be 
                  famous guests, Press and other media outlets pertaining to the event with professional 
                  Videography and Photography. 
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  F1 VIP Paddock
                  </span>
                  <p>
                  F1 <i>Italian GP</i> Date: Sun, 11 Sept, 15:00 Track: Autodromo Nazionale Monza 2 NFT Holders will have the 
                  tickets embedded in the TokenID as a trait for the exclusive Paddock access.
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  Fashion Week Events
                  </span>
                  <p>
                  Date: September 1 NFT Holder will have the tickets embedded in the TokenID as a trait for exclusive access with
                   the opportunity to bring a plus 1. The Events and times will be updated as we near.
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  MURAL WALL TOUR 
                  </span>
                  <p>
                  MDV and Fieno will be going on a mural wall tour of 1/1 bears in Hot locations where people can take pictures 
                  of our graffiti bears. If you&#39;re a holder and shill the post on Twitter you will receive a giveaway. More information 
                  to come in the upcoming weeks.
                  </p>
                </li>
              </ul>
              <p className={classes.TermsModalTitle}>Q4</p>
              <ul className={classes.listitems}>
                <li>
                  <span className={classes.bolditems}>
                  Gen 2 Release and Staking Location
                  </span>
                  <p>
                  Shortly after the Fashion Event in Q3 we will be releasing the Gen 2 mints as well as staking location for 
                  royalties to come into effect.
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  Public HOUSE OFBELIEVERS Merch Website
                  </span>
                  <p>
                  Collections will be available for the public to purchase online. Exclusive member access merch/collections will 
                  remain token gated. Therefore new holders will have access to the exclusive merch/collections.
                  </p> 
                </li>
                <li>
                  <span className={classes.bolditems}>
                  HOUSE OFBELIEVERS Winter Collection Fashion Event/Runway
                  </span>
                  <p>
                  We will be hosting our very own runway and fashion event of our summer collection. We will be inviting 5 Female and 
                  5 Male models from our very own community to model this collection. If you don&#39;t know how to model don&#39;t worry MDV 
                  will be giving you a crash course for modeling and secrets he learned throughout his career.There will be famous 
                  guests, Press and other media outlets pertaining to the event with professional Videography and Photography.
                  </p>
                </li>
                <li>
                  <span className={classes.bolditems}>
                  POP-UP SHOP 
                  </span>
                  <p>
                  We will employ community members in the respected region of the POP-UP shop. More information is coming soon.
                  </p>
                </li>
              </ul>
              <p className={classes.TermsModalTitle}>Addition Features</p>
              <ul className={classes.listitems}>
                <li>
                  <span className={classes.bolditems}>
                  Twitter Giveaways 
                  </span>
                  <p>
                  We will be giving away T-shirts with a Bear that will have a QR Code on the back that will bring them to the 
                  Linktree/Website. We will also do surprise 1/1 giveaways as well and release teasers when they&#39;re ready. 
                  How to qualify; 1. Shill a sneak peek bear with new mint date 2. Hype up one thing on our roadmap and include 
                  it in the tweet.
                  </p>
                </li>
              </ul>
              <i>All the above activities and events&#39; availability will be subject
              to local health and safety measures and regulations. Roadmap will be completed as infographics dictate.</i>
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className={classes.FooterBackground}>
        <div className={classes.flexboxcontainer}>
          <span className={classes.TermsAndConditions} onClick={openModel}>
          White Paper
          </span>
        </div>
      </div>

      <div className={classes.HeaderBackground}>
        <div className={classes.SMScontainer}>
          <div className={classes.SMSIcons}>
            <a
              href="https://discord.com/invite/nohow-squad-nft"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="Discord"
                src="discord.png"
                className={classes.SMSimage}
              />
            </a>
            <a
              href="https://www.instagram.com/nohow_nft/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img alt="Instagram" src="ig.png" className={classes.SMSimage} />
            </a>
            <a
              href="https://twitter.com/Hofbelievers"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="Twitter"
                src="twitter.png"
                className={classes.SMSimage}
              />
            </a>
            <a
              href="https://nft.nohowstyle.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="NoHow NFT"
                src="nohow.png"
                className={classes.SMSimage}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
