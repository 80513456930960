import React from "react";
import { useEffect, useState } from "react";
import { getCurrentSaleState } from "./utils/interact.js";
import classes from "./QuantitySelector.module.css";

const QuantitySelector = ({ numberOfMints, setNumberOfMints }) => {
  const [saleState, setSaleState] = useState("");
  const [maxQuantity, setMaxQuantity] = useState(3);

  useEffect(() => {
    getSaleState();
    if (saleState == "OG" || saleState == "Closed") {
      setMaxQuantity(3);
    }
    if (saleState == "WL" || saleState == "preWL") {
      setMaxQuantity(2);
    }
    if (saleState == "Community" || saleState == "preCommunity") {
      setMaxQuantity(2);
    }
    if (saleState == "Public" || saleState == "prePublic") {
      setMaxQuantity(100);
    }
  }, [saleState]);

  function handleIncreaseClick() {
    if (numberOfMints < maxQuantity) {
      setNumberOfMints(numberOfMints + 1);
    }
  }

  function handleDecreaseClick() {
    if (numberOfMints > 1) {
      setNumberOfMints(numberOfMints - 1);
    }
  }

  const getSaleState = async () => {
    const { liveSaleState } = await getCurrentSaleState();
    setSaleState(liveSaleState);
  };

  return (
    <div>
      <span className={classes.qtySelector}>
        <span className={classes.amount}>Amount</span>

        <button
          type="button"
          id={classes.selectButtons}
          onClick={() => {
            handleDecreaseClick();
          }}
        >
          -
        </button>
        <span className={classes.numberOfMints}>{numberOfMints}</span>
        <button
          type="button"
          id={classes.selectButtons}
          onClick={() => {
            handleIncreaseClick();
          }}
        >
          +
        </button>
      </span>
    </div>
  );
};

export default QuantitySelector;
