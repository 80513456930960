import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintPublic,
  mintCommunity,
  mintWL,
  mintOG,
  openInNewTab,
  totalBears,
  getCurrentSaleState
} from "./utils/interact.js";
import classes from "./Minter.module.css";
import QuantitySelector from "./QuantitySelector";
import Confetti from "./objects/confetti";

const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [numberOfMints, setNumberOfMints] = useState(2);
  const [whitelistStatus, setWhitelistStatus] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [saleState, setSaleState] = useState("");
  const [mintPrice, setMintPrice] = useState(0.06);

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    getTotalSupply();
    getSaleState();
    addWalletListener();
  }, []);

  useEffect(async () => {
    if (saleState == "OG" || saleState == "Closed") {
      setMintPrice(0.06);
    }
    if (saleState == "WL" || saleState == "preWL") {
      setMintPrice(0.079);
    }
    if (saleState == "Community" || saleState == "preCommunity") {
      setMintPrice(0.099);
    }
    if (saleState == "Public" || saleState == "prePublic") {
      setMintPrice(0.1);
    }
  }, [saleState]);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  function finalAnimation() {
    setTimeout(() => Confetti(), 5000);
  }

  const onOGMint = async () => {
    const { success, mintStatus } = await mintOG(numberOfMints);

    //Successful transaction
    if (success === "true") {
      //doFinalTransition();
      finalAnimation();
      //No metamask installed
    } else if (success === "false") {
      setStatus(mintStatus);
      openInNewTab("https://metamask.io/download.html");

      //Error during transaction
    } else if (success === "null") {
      setStatus(mintStatus);
    }
  };

  const onWLMint = async () => {
    const { success, mintStatus } = await mintWL(numberOfMints);
    //Successful transaction
    if (success === "true") {
      //doFinalTransition();
      finalAnimation();
      //No metamask installed
    } else if (success === "false") {
      setStatus(mintStatus);
      openInNewTab("https://metamask.io/download.html");

      //Error during transaction
    } else if (success === "null") {
      setStatus(mintStatus);
    }
  };

  const onCommunityMint = async () => {
    const { success, mintStatus } = await mintCommunity(numberOfMints);

    //Successful transaction
    if (success === "true") {
      //doFinalTransition();
      finalAnimation();
      //No metamask installed
    } else if (success === "false") {
      setStatus(mintStatus);
      openInNewTab("https://metamask.io/download.html");
      //Error during transaction
    } else if (success === "null") {
      setStatus(mintStatus);
    }
  };

  const onPublicMint = async () => {
    const { success, mintStatus } = await mintPublic(numberOfMints);
    //Successful transaction
    if (success === "true") {
      //doFinalTransition();
      finalAnimation();
      //No metamask installed
    } else if (success === "false") {
      setStatus(mintStatus);
      openInNewTab("https://metamask.io/download.html");
      //Error during transaction
    } else if (success === "null") {
      setStatus(mintStatus);
    }
  };

  function handleMint() {
    if (saleState === "OG") {
      onOGMint();
    } else if (saleState == "WL") {
      onWLMint();
    } else if (saleState == "Community") {
      onCommunityMint();
    } else {
      onPublicMint();
    }
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const getTotalSupply = async () => {
    const { totalSupply } = await totalBears();
    setTotalSupply(totalSupply);
  };

  const getSaleState = async () => {
    const { liveSaleState } = await getCurrentSaleState();
    setSaleState(liveSaleState);
  };

  return (
    <div className={classes.Minter}>
      <div className={classes.textwrapper}>
        <form className={classes.form}>
          <h1 className={classes.formTitle}> JOIN THE SQUAD </h1>
          <p id={classes.nftSupply}>Total Minted: {totalSupply}/4444</p>
          <br></br>
          <QuantitySelector
            numberOfMints={numberOfMints}
            setNumberOfMints={setNumberOfMints}
          />
          <hr></hr>
          <p>
            <span className={classes.total}>Total </span>
            <span className={classes.pricespan}>
              {(numberOfMints * mintPrice).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                useGrouping: false
              })}{" "}
              ETH <br /> <span className={classes.fee}>+fee</span>
            </span>
          </p>
          {saleState == "OG" ||
          saleState == "WL" ||
          saleState == "Community" ||
          saleState == "Public" ? (
            <button type="button" id={classes.mintButton} onClick={handleMint}>
              mint now
            </button>
          ) : (
            <button type="button" id={classes.mintButton}>
              mint closed
            </button>
          )}
          <p id={classes.status}>{status}</p>
        </form>
      </div>
    </div>
  );
};

export default Minter;
