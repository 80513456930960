import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  liveSaleState,
  getCurrentSaleState
} from "./utils/interact.js";
import classes from "./Header.module.css";

const Header = () => {
  //State variables
  const [walletAddress, setWallet] = useState(""); // - a string that stores the user's wallet address
  const [renderedTitle, setRenderedTitle] = useState("");
  const [status, setStatus] = useState(""); // - a string that contains a message to display at the bottom of the UI
  const [whitelistStatus, setWhitelistStatus] = useState("");
  const [connectButton, setConnectButton] = useState("Connect Wallet");
  const [saleState, setSaleState] = useState("");

  useEffect(async () => {
    if (saleState == "Closed") {
      setRenderedTitle("GENESIS MINT BEGINS IN");
    }
    if (saleState == "OG") {
      setRenderedTitle("GENESIS MINT ENDS IN");
    }
    if (saleState == "preWL") {
      setRenderedTitle("MINTLIST MINT BEGINS IN");
    }
    if (saleState == "WL") {
      setRenderedTitle("MINTLIST MINT ENDS IN");
    }
    if (saleState == "preCommunity") {
      setRenderedTitle("COMMUNITY MINT BEGINS IN");
    }
    if (saleState == "Community") {
      setRenderedTitle("COMMUNITY MINT ENDS IN");
    }
    if (saleState == "prePublic") {
      setRenderedTitle("MINT CLOSED");
    }
    if (saleState == "Public") {
      setRenderedTitle("PUBLIC MINT IS NOW LIVE!");
    }
  }, [saleState]);

  useEffect(async () => {
    getCurrentWallet();
    addWalletListener();
    getSaleState();
  }, []);

  async function getCurrentWallet() {
    const { address, status, whitelistStatus } =
      await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    setWhitelistStatus(whitelistStatus);
    if (address.length > 0) {
      setConnectButton(
        String(address).substring(0, 6) + "..." + String(address).substring(38)
      );
    } else {
      setConnectButton("Connect Wallet");
    }
  }

  const connectWalletPressed = async () => {
    if (connectButton === "Connect Wallet") {
      const { address, status, whitelistStatus } = await connectWallet();
      setWallet(address);
      setStatus(status);
      setWhitelistStatus(whitelistStatus);
      if (address.length > 0) {
        setConnectButton(
          String(address).substring(0, 6) +
            "..." +
            String(address).substring(38)
        );
      } else {
        setConnectButton("Connect Wallet");
      }
    } else {
      window.open("https://etherscan.io/address/" + walletAddress);
    }
  };

  function addWalletListener() {
    //Check if wallet installed
    if (window.ethereum) {
      //If it is installed setup a listener that listens for state changes in the metamask wallet
      //Includes when user connects an additioanl account to dApp, switches accounts or disconnects accounts
      window.ethereum.on("accountsChanged", (accounts) => {
        getCurrentWallet();
      });
      //If not set status as a JSX string of where to download metamask
    } else {
      setStatus(
        "😥 You must install Metamask, a virtual Ethereum wallet, in your browser."
      );
    }
  }

  const getSaleState = async () => {
    const { liveSaleState } = await getCurrentSaleState();
    setSaleState(liveSaleState);
  };

  return (
    <div className={classes.HeaderBackground}>
      <div className={classes.flexboxcontainer}>
        <div className={classes.SMScontainer}>
          <div className={classes.SMSIcons}>
            <a
              href="https://discord.com/invite/nohow-squad-nft"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="Discord"
                src="discord.png"
                className={classes.SMSimage}
              />
            </a>
            <a
              href="https://www.instagram.com/nohow_nft/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img alt="Instagram" src="ig.png" className={classes.SMSimage} />
            </a>
            <a
              href="https://twitter.com/Hofbelievers"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="Twitter"
                src="twitter.png"
                className={classes.SMSimage}
              />
            </a>
            <a
              href="https://nft.nohowstyle.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="NoHow NFT"
                src="nohow.png"
                className={classes.SMSimage}
              />
            </a>
          </div>
        </div>
        <div className={classes.Headercontainer}>
          <span id={classes.title}>{renderedTitle}</span>
        </div>
        <div className={classes.Buttoncontainer}>
          {" "}
          <button id={classes.walletButton} onClick={connectWalletPressed}>
            {walletAddress.length > 0 ? (
              "Connected: " +
              String(walletAddress).substring(0, 6) +
              "..." +
              String(walletAddress).substring(38)
            ) : (
              <span>CONNECT WALLET</span>
            )}
          </button>
        </div>
      </div>
      <span className={classes.subheader}>LET'S BUILD A NEW FASHION BRAND</span>
    </div>
  );
};

export default Header;
