import "./App.css";
import Minter from "./Minter";
import Header from "./Header";
import Footer from "./Footer";
import VideoBackground from "./VideoBackground";
import Countdown from "./Countdown";
import LeftInfo from "./LeftInfo";

function App() {
  return (
    <div className="App">
      <Header />
      <VideoBackground />
      <LeftInfo />
      <Minter />
      <Footer />
    </div>
  );
}

export default App;
