import bgVideo from "../video/mobile_video.mp4";
import classes from "./VideoBackgroundMobile.module.css";

const VideoBackgroundMobile = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${bgVideo}"
          class="${classes.video}"
        />,
      `
      }}
    ></div>
  );
};

export default VideoBackgroundMobile;
