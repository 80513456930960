import classes from "./LeftInfo.module.css";
import { useEffect, useState } from "react";

function LeftInfo() {
  const [termsVisible, setTermsVisible] = useState(false);
  function openModel() {
    setTermsVisible(true);
  }

  function closeModel() {
    setTermsVisible(false);
  }

  return (
    <div>
      {termsVisible == true ? (
        <div className={classes.TermsModal}>
          <div className={classes.TermsModalContent}>
            <span
              className={classes.TermsModalCloseButton}
              onClick={closeModel}
            >
              &times;
            </span>{" "}
            <p className={classes.TermsModalContent}>
              <p className={classes.TermsModalTitle}>Terms </p>
              <p className={classes.TermsModalText}>
                Minting a GEN 1 NFT collectible will provide you access to MINT
                a GEN 2 NFT. <br />
                Holding a GEN 1 & GEN 2 will grant you a % of License on the
                brand. The more you have the bigger the more royalties you will
                receive. <br />
                Let's build, let's have fun, LFG. *white paper GEN 2 coming soon
                <br />
              </p>
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={classes.mainContainer}>
        <div className={classes.borderContainer}>
          <span className={classes.infoContainer}>
            "EARN ONGOING <br />
            <span className={classes.bluetext}>% OF LICENSE FEES</span> FOR A
            GLOBAL FASHION BRAND" <br />
            <span className={classes.subjecttoterms} onClick={openModel}>
              *Subject to Terms
            </span>
          </span>
        </div>

        <br />
        <p className={classes.bottomText}>WHY US?</p>
        <a
          href="https://www.instagram.com/nohow_nft/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span className={classes.links}>COMPANY</span>
        </a>
        <span>{"      "}</span>
        <a
          href="https://www.instagram.com/marianodivaio/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span className={classes.links}>MDV</span>
        </a>
      </div>
    </div>
  );
}

export default LeftInfo;

{
  /* <a
href="https://www.instagram.com/nohow_nft/"
target="_blank"
rel="noreferrer noopener"
>
<span className={classes.links}>COMPANY </span>
</a>
<a
href="https://www.instagram.com/marianodivaio/"
target="_blank"
rel="noreferrer noopener"
>
<span className={classes.links}> CEO</span>
</a> */
}
