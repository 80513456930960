import { useState, useEffect } from "react";
import { getCurrentSaleState } from "../utils/interact.js";
import classes from "./CountdownMobile.module.css";

function CountdownMobile() {
  const [releaseDate, setReleaseDate] = useState("2022-04-03T17:00:00Z");
  const [saleState, setSaleState] = useState("");
  const [seconds, setSeconds] = useState(
    Math.floor((new Date(releaseDate).getTime() - new Date().getTime()) / 1000)
  );

  useEffect(async () => {
    getSaleState();
    if (saleState == "Closed") {
      setReleaseDate("2022-05-02T17:00:00Z");
    }
    if (saleState == "OG") {
      setReleaseDate("2022-05-03T17:00:00Z");
    }
    if (saleState == "preWL") {
      setReleaseDate("2022-05-03T18:00:00Z");
    }
    if (saleState == "WL") {
      setReleaseDate("2022-05-04T18:00:00Z");
    }
    if (saleState == "preCommunity") {
      setReleaseDate("2022-05-04T19:00:00Z");
    }
    if (saleState == "Community") {
      setReleaseDate("2022-05-05T19:00:00Z");
    }
    if (saleState == "prePublic") {
      setReleaseDate("2022-05-04T19:00:00Z");
    }
    if (saleState == "Public") {
      setReleaseDate("2022-05-04T20:00:00Z");
    }
  }, [saleState]);

  useEffect(() => {
    setSeconds(
      Math.floor(
        (new Date(releaseDate).getTime() - new Date().getTime()) / 1000
      )
    );
  }, [releaseDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1);
      } else if (seconds <= 0) {
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const getSaleState = async () => {
    const { liveSaleState } = await getCurrentSaleState();
    setSaleState(liveSaleState);
  };

  return (
    <div className={classes.countdownDiv}>
      <div className={classes.flexboxcontainer}>
        <div className={classes.flexbox1}>
          <span className={classes.countdownlabels}>DAYS</span> <br></br>
          <span className={classes.countdownnumbers}>
            {seconds > 0
              ? Math.floor(seconds / 86400).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
              : "00"}
          </span>
        </div>
        <div className={classes.flexboxcolon}>
          <span className={classes.invisible}>.</span>
          <br></br> <span className={classes.countdownnumbers}> : </span>
        </div>
        <div className={classes.flexbox1}>
          <span className={classes.countdownlabels}>HOURS</span> <br></br>
          <span className={classes.countdownnumbers}>
            {seconds > 0
              ? Math.floor(
                  (seconds - Math.floor(seconds / 86400) * 86400) / 3600
                ).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
              : "00"}
          </span>
        </div>
        <div className={classes.flexboxcolon}>
          <span className={classes.invisible}>.</span>
          <br></br> <span className={classes.countdownnumbers}> : </span>
        </div>
        <div className={classes.flexbox1}>
          <span className={classes.countdownlabels}>MINUTES</span> <br></br>
          <span className={classes.countdownnumbers}>
            {seconds > 0
              ? Math.floor(
                  (seconds - Math.floor(seconds / 3600) * 3600) / 60
                ).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
              : "00"}
          </span>
        </div>
        <div className={classes.flexboxcolon}>
          <span className={classes.invisible}>.</span>
          <br></br> <span className={classes.countdownnumbers}> : </span>
        </div>
        <div className={classes.flexbox1}>
          <span className={classes.countdownlabels}>SECONDS</span> <br></br>
          <span className={classes.countdownnumbers}>
            {seconds > 0
              ? (seconds - Math.floor(seconds / 60) * 60).toLocaleString(
                  "en-US",
                  {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  }
                )
              : "00"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CountdownMobile;
