const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const OGAddresses = require("../whitelists/OG.json");
const WLAddresses = require("../whitelists/WL.json");
const CommunityAddresses = require("../whitelists/community.json");

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractABI = require("../contract-abi.json");
const contractAddress = "0x76A9c69C6491Ca42af9FeE3baa5A5de6d1eeFbe4";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const WLLeaves = WLAddresses.map((x) => keccak256(x));
const OGLeaves = OGAddresses.map((x) => keccak256(x));
const CommunityLeaves = CommunityAddresses.map((x) => keccak256(x));

const WLMerkletree = new MerkleTree(WLLeaves, keccak256, { sortPairs: true });
const OGMerkletree = new MerkleTree(OGLeaves, keccak256, { sortPairs: true });
const CommunityMerkletree = new MerkleTree(CommunityLeaves, keccak256, {
  sortPairs: true
});

const WLAddressesLowerCase = WLAddresses.map((x) => x.toLowerCase());
const OGAddressesLowerCase = OGAddresses.map((x) => x.toLowerCase());
const CommunityAddressesLowerCase = CommunityAddresses.map((x) =>
  x.toLowerCase()
);

const OGPrice = 60000000000000000;
const MLPrice = 79000000000000000;
const Price = 100000000000000000;

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      var whitelisted = "Not Whitelisted";

      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts"
      });

      if (WLAddressesLowerCase.indexOf(addressArray[0]) > -1) {
        whitelisted = "WL";
      }

      if (CommunityAddressesLowerCase.indexOf(addressArray[0]) > -1) {
        whitelisted = "Community";
      }

      if (OGAddressesLowerCase.indexOf(addressArray[0]) > -1) {
        whitelisted = "OG";
      }

      const obj = {
        status: "",
        address: addressArray[0],
        whitelistStatus: whitelisted
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
        whitelistStatus: whitelisted
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://metamask.io/download.html`}
            >
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
      whitelistStatus: whitelisted
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts"
      });

      var whitelisted = "Not Whitelisted";

      if (addressArray.length > 0) {
        if (WLAddressesLowerCase.indexOf(addressArray[0]) > -1) {
          whitelisted = "WL";
        }

        if (CommunityAddressesLowerCase.indexOf(addressArray[0]) > -1) {
          whitelisted = "Community";
        }

        if (OGAddressesLowerCase.indexOf(addressArray[0]) > -1) {
          whitelisted = "OG";
        }

        return {
          address: addressArray[0],
          status: "",
          whitelistStatus: whitelisted
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the connect wallet button.",
          whitelistStatus: whitelisted
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
        whitelistStatus: whitelisted
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://metamask.io/download.html`}
            >
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
      whitelistStatus: whitelisted
    };
  }
};

export const mintOG = async (numberOfMints) => {
  if (window.ethereum) {
    const addressArray = await window.ethereum.request({
      method: "eth_requestAccounts"
    });
    const sendingAddress = addressArray[0];
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const OGHexProof = OGMerkletree.getHexProof(keccak256(sendingAddress));
    const transactionValue = OGPrice * numberOfMints;
    //set up your Ethereum transaction
    const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: sendingAddress, // must match user's active address.
      value: web3.utils.toHex(transactionValue),
      data: window.contract.methods
        .mintOGSale(numberOfMints, OGHexProof)
        .encodeABI() //make call to NFT smart contract
    };
    //sign transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters]
      });
      return {
        success: "true",
        mintStatus: (
          <span>
            <p>
              {" "}
              ✅{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://etherscan.io//tx/` + txHash}
              >
                Check the progress of your transaction here!
              </a>
            </p>
          </span>
        )
      };
    } catch (error) {
      return {
        success: "null",
        mintStatus: "😥 Something went wrong: " + error.message
      };
    }
  } else {
    return {
      success: "false",
      mintStatus:
        "😥 No Metamask found, please install the relevant version for your device."
    };
  }
};

export const mintWL = async (numberOfMints) => {
  if (window.ethereum) {
    const addressArray = await window.ethereum.request({
      method: "eth_requestAccounts"
    });
    const sendingAddress = addressArray[0];
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const WLHexProof = WLMerkletree.getHexProof(keccak256(sendingAddress));
    const transactionValue = MLPrice * numberOfMints;
    //set up your Ethereum transaction
    const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: sendingAddress, // must match user's active address.
      value: web3.utils.toHex(transactionValue),
      data: window.contract.methods
        .mintWLSale(numberOfMints, WLHexProof)
        .encodeABI() //make call to NFT smart contract
    };
    //sign transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters]
      });
      return {
        success: "true",
        mintStatus: (
          <span>
            <p>
              {" "}
              ✅{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://etherscan.io//tx/` + txHash}
              >
                Check the progress of your transaction here!
              </a>
            </p>
          </span>
        )
      };
    } catch (error) {
      return {
        success: "null",
        mintStatus: "😥 Something went wrong: " + error.message
      };
    }
  } else {
    return {
      success: "false",
      mintStatus:
        "😥 No Metamask found, please install the relevant version for your device."
    };
  }
};

export const mintCommunity = async (numberOfMints) => {
  if (window.ethereum) {
    const addressArray = await window.ethereum.request({
      method: "eth_requestAccounts"
    });
    const sendingAddress = addressArray[0];
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const CommunityHexProof = CommunityMerkletree.getHexProof(
      keccak256(sendingAddress)
    );
    const transactionValue = Price * numberOfMints;
    //set up your Ethereum transaction
    const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: sendingAddress, // must match user's active address.
      value: web3.utils.toHex(transactionValue),
      data: window.contract.methods
        .mintCommunitySale(numberOfMints, CommunityHexProof)
        .encodeABI() //make call to NFT smart contract
    };
    //sign transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters]
      });
      return {
        success: "true",
        mintStatus: (
          <span>
            <p>
              {" "}
              ✅{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://etherscan.io//tx/` + txHash}
              >
                Check the progress of your transaction here!
              </a>
            </p>
          </span>
        )
      };
    } catch (error) {
      return {
        success: "null",
        mintStatus: "😥 Something went wrong: " + error.message
      };
    }
  } else {
    return {
      success: "false",
      mintStatus:
        "😥 No Metamask found, please install the relevant version for your device."
    };
  }
};

export const mintPublic = async (numberOfMints) => {
  if (window.ethereum) {
    const addressArray = await window.ethereum.request({
      method: "eth_requestAccounts"
    });
    const sendingAddress = addressArray[0];
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionValue = Price * numberOfMints;
    //set up your Ethereum transaction
    const transactionParameters = {
      to: contractAddress, // Required except during contract publications.
      from: sendingAddress, // must match user's active address.
      value: web3.utils.toHex(transactionValue),
      data: window.contract.methods.mintPublic(numberOfMints).encodeABI() //make call to NFT smart contract
    };
    //sign transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters]
      });
      return {
        success: "true",
        mintStatus: (
          <span>
            <p>
              {" "}
              ✅{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://etherscan.io//tx/` + txHash}
              >
                Check the progress of your transaction here!
              </a>
            </p>
          </span>
        )
      };
    } catch (error) {
      return {
        success: "null",
        mintStatus: "😥 Something went wrong: " + error.message
      };
    }
  } else {
    return {
      success: "false",
      mintStatus:
        "😥 No Metamask found, please install the relevant version for your device."
    };
  }
};

//Function which obtains the total tokens minted on the contract
export async function totalBears() {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  var totalTokens = await window.contract.methods.totalSupply().call();
  return {
    totalSupply: totalTokens
  };
}

//Function which obtains the total tokens minted on the contract
export async function getCurrentSaleState() {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  var saleState = await window.contract.methods.currentSaleState().call();
  return {
    liveSaleState: saleState
  };
}

export function openInNewTab(url) {
  window.open(url, "_blank").focus();
}
