import bgVideo from "./video/video_crop.mp4";
import classes from "./VideoBackground.module.css";

const VideoBackground = () => {
  return (
    <div>
      <video autoPlay loop muted className={classes.video}>
        <source src={bgVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoBackground;
