import "./App.css";
import MinterMobile from "./mobile/MinterMobile";
import HeaderMobile from "./mobile/HeaderMobile";
import VideoBackgroundMobile from "./mobile/VideoBackgroundMobile";
import CountdownMobile from "./mobile/CountdownMobile";
import FooterMobile from "./mobile/FooterMobile";
import LeftInfoMobile from "./mobile/LeftInfoMobile";

function App() {
  return (
    <div className="App">
      <HeaderMobile />
      <VideoBackgroundMobile />
      <MinterMobile />
      <LeftInfoMobile />
      <FooterMobile />
    </div>
  );
}

export default App;
